// eslint-disable-next-line no-redeclare,no-unused-vars
function buildCalendar(cont, param, xhr) {
  var view = param.view;
  var object = param.object;
  var objectSub = param.objectSub;
  var settings = parameter[object];
  var registered = [];
  var rendered = [];
  var notClickableCheck = getParamSettings(param, 'notClickable');
  var resourceFilter = null;
  var initialView = null;
  var viewSwitcher = null;
  var schedulerLicenseKey = 'CC-Attribution-NonCommercial-NoDerivatives';

  // table attributes
  var table = {};
  table.ids = [];
  table.where = xhr.where ? xhr.where : {};
  table.filter = {};
  table.filterJs = {};
  if (view) view.table = table;
  param.table = table;
  param.startKey = settings.startKey;
  param.endKey = settings.endKey;
  param.resource = settings['resource' + ucfirst(objectSub)];

  var element = $('<div></div>').appendTo(cont);
  element.table = table;
  registerTable(table, object, param);

  // load data
  table.loadData = function (param2 = {}, calObj, start, end) {
    if (param2.refresh) table.clear();

    // change calendar view from view switcher
    if (param2 && param2.fromFilter && table.calendarView && viewSwitcher) {
      initialView = table.calendarView;
      cal.changeView(table.calendarView);
      viewSwitcher.element.val(table.calendarView);
      return;
    }

    if (!calObj) calObj = cal;
    if (!start) start = calObj.view.activeStart;
    if (!end) end = calObj.view.activeEnd;
    if (param2.init && table.ids.length) table.ids = [];
    var calendarView = table.calendarView;
    var send = {
      calendar: true,
      where: table.where,
      search: table.search,
      start: table.start,
      end: table.end,
    };
    if (table.tableFields) send.tableFields = table.tableFields;
    if (table.filter) send.where = table.filter;
    if (param.where) send.where = Object.assign({}, param.where, send.where);
    if (
      param.resource &&
      isArray(send.where[param.resource]) &&
      !store.calendarHideUnassign
    )
      send.where[param.resource].push(0);

    // date filter
    var or = [];
    or.push({
      start: [
        '>=' + convertToMoment(start).format('YYYY-MM-DD'),
        '<=' + convertToMoment(end).format('YYYY-MM-DD'),
      ],
    });
    or.push({
      end: [
        '>=' + convertToMoment(start).format('YYYY-MM-DD'),
        '<=' + convertToMoment(end).format('YYYY-MM-DD'),
      ],
    });
    or.push({
      start: '<' + convertToMoment(start).format('YYYY-MM-DD'),
      end: '>' + convertToMoment(end).format('YYYY-MM-DD'),
    });
    send.where.$and = {$or: or};

    // get values
    var loadDataPart = function (page = 0) {
      pm = {
        object: object,
        objectSub: objectSub,
        send: send,
        noTableCheck: true,
        callback: function (xhr) {
          // add events
          if (xhr.ids)
            $.each(xhr.ids, function (i, id) {
              if (!inArray(id, table.ids))
                setTimeout(function () {
                  if (
                    calendarView != table.calendarView ||
                    $(calObj.el).is(':hidden') ||
                    inArray(id, table.ids)
                  )
                    return;

                  // requestIdleCallback(function () {
                  var row = getData(object, id);
                  if (!row) return;
                  calObj.addEvent(convertEvent(row, param));
                  table.ids.push(id);
                  // });
                }, 1);
            });

          // load more data
          if (xhr.amount > (page + 1) * 100) {
            setTimeout(function () {
              if (
                calendarView != table.calendarView ||
                $(calObj.el).is(':hidden')
              )
                return;

              loadDataPart(page + 1);
            }, 1);
          }
        },
      };
      pm.send.page = page;
      if (page) pm.background = true;
      ajax(pm);
    };
    loadDataPart();
  };

  // param, events
  var pm = {
    locale: 'de',
    aspectRatio: 5,
    weekNumbers: true,
    eventDisplay: 'block',
    firstDay: 1,
    editable: true,
    displayEventTime: false,
    nowIndicator: true,
    schedulerLicenseKey: schedulerLicenseKey,
    headerToolbar: {
      start: 'prev,next today',
      center: 'title',
      end: '',
    },
    multiMonthMaxColumns: 1,
    titleRangeSeparator: ' - ',
    defaultTimedEventDuration: '01:00',
    views: {
      multiMonthFourMonth: {
        type: 'multiMonth',
        duration: {months: 4},
      },
    },
  };
  pm.events = [];
  if (user.calendarHoursStart) pm.slotMinTime = user.calendarHoursStart;
  else pm.slotMinTime = '8:00';
  if (user.calendarHoursEnd) pm.slotMaxTime = user.calendarHoursEnd;
  else pm.slotMaxTime = '20:00';
  if (getParamSettings(param, 'noWeekend')) pm.weekends = false;
  else if (user.noWeekend) pm.weekends = false;
  if (heightCalendar) pm.contentHeight = heightCalendar;
  if (xhr.ids)
    $.each(xhr.ids, function (i, id) {
      var row = getData(object, id);
      if (!row) return;
      pm.events.push(convertEvent(row, param));
      table.ids.push(id);
    });

  // calendar views
  let viewValues = [
    'dayGridDay',
    'timeGridDay',
    'listDay',
    'dayGridWeek',
    'timeGridWeek',
    'listWeek',
    'dayGridMonth',
    'listMonth',
    'multiMonthYear',
    'listYear',
  ];
  let viewLabels = [
    'Tag',
    'Tag (mit Zeiten)',
    'Tag (Liste)',
    'Woche',
    'Woche (mit Zeiten)',
    'Woche (Liste)',
    'Monat',
    'Monat (Liste)',
    'Jahr',
    'Jahr (Liste)',
  ];
  if (param.resource) {
    viewValues = [
      'resourceTimelineDay',
      'resourceDayGridDay',
      'resourceTimeGridDay',
      'resourceTimelineWeek',
      'resourceTimelineWeek2',
      'resourceDayGridWeek',
      'resourceTimeGridWeek',
      'resourceTimelineMonth',
      'resourceDayGridMonth',
      'resourceTimelineYear',
    ];
    viewLabels = [
      'Tag Zeitleiste',
      'Tag Tagesgitter',
      'Tag Zeitgitter',
      'Woche Zeitleiste',
      'Woche Tagesleiste',
      'Woche Tagesgitter',
      'Woche Zeitgitter',
      'Monat Tagesleiste',
      'Monat Tagesgitter',
      'Jahr Tagesleiste',
    ];
  }

  // resource calendar
  if (param.resource && fields[object][param.resource]) {
    var resourceIdent = view.ident + '_resource';
    var list = {};

    pm.views = {
      resourceTimelineWeek2: {
        type: 'resourceTimeline',
        duration: {weeks: 1},
        slotDuration: {days: 1},
      },
    };
    pm.initialView = 'resourceTimelineMonth';
    pm.resourceAreaWidth = '150px';
    pm.resourceAreaHeaderContent = ' ';
    pm.resources = [];
    if (!store.calendarHideUnassign)
      pm.resources.push({id: 0, title: 'nicht zugeordnet', sortNo: 0});
    pm.resourceOrder = 'sortNo';
    registerList(element, param.resource);
    element.valList = function () {
      view.update();
    };

    // control filter field
    param.searchFilter = {};
    param.searchFilter[param.resource] = {
      callback: function (value) {
        element.clearResources();
        resourceFilter = value;
        list = {};
        if (resourceFilter)
          $.each(resourceFilter, function (i, id) {
            list[id] = data.lists[resourceIdent][id];
          });
        table.addResources(list);
      },
    };

    // handle store filter
    if (multiStore)
      param.searchFilter.store = {
        callback: function (value) {
          table.getResourceData({store: value});
        },
      };

    // update resources
    table.getResourceData = function (where, fromViewSaver) {
      var pm = {
        object: object,
        send: {
          func: 'convertRefList',
          key: param.resource,
          list: resourceIdent,
        },
      };
      if (!fromViewSaver)
        pm.callback = function () {
          element.clearResources();
          if (resourceFilter)
            $.each(resourceFilter, function (i, id) {
              list[id] = data.lists[resourceIdent][id];
            });
          else list = data.lists[resourceIdent];
          table.addResources(list);
        };
      if (where) pm.send.where = where;
      ajax(pm);
    };

    // add resource
    table.addResources = function (list) {
      $.each(list, function (id, title) {
        if (!id && !title) return;
        var pm2 = {id: id, title: title};
        if (data.lists.sequence && data.lists.sequence[resourceIdent])
          pm2.sortNo = data.lists.sequence[resourceIdent].indexOf(parseInt(id));
        cal.addResource(pm2);
      });
      table.filter[param.resource] = Object.keys(list);
      table.loadData();

      // control filter field
      setTimeout(function () {
        if (
          param.searchFilter[param.resource] &&
          param.searchFilter[param.resource].element
        )
          param.searchFilter[param.resource].element.val(Object.keys(list));
      }, 500);
    };

    // clear resources
    element.clearResources = function () {
      var resources = cal.getResources();
      $.each(resources, function (i, resource) {
        if (resource && resource.id != '0') resource.remove();
      });
    };
  }
  pm.buttonHints = pm.buttonText;

  // check default view saver
  var views = getParamSettings(
    {object: param.object, objectSub: param.objectSub},
    'view'
  );
  if (views) {
    var i = arraySearchAssoc(views, 'default', 1);
    if (i !== false) {
      param.defaultView = i;
      if (views[i].filter) {
        table.filter = views[i].filter;
        if (param.resource && table.filter[param.resource])
          resourceFilter = table.filter[param.resource];
        if (views[i].calendarView) initialView = views[i].calendarView;
      }
    }
  }

  // add initial view
  if (!initialView)
    initialView = localStorage.getItem(param.ident + '_ViewInitial');
  if (!initialView && settings['default' + ucfirst(objectSub)]) {
    initialView = settings['default' + ucfirst(objectSub)];
    if (initialView == 'agendaWeek') initialView = 'timeGridWeek';
    else if (initialView == 'timelineMonth')
      initialView = 'resourceTimelineMonth';
  }
  if (initialView && inArray(initialView, viewValues))
    pm.initialView = initialView;
  if (pm.initialView === 'resourceTimeGridWeek') pm.dayMinWidth = 80;
  // table.calendarView = pm.initialView;
  if (param.send && param.send.start) pm.initialDate = param.send.start;

  // event click
  pm.eventClick = function (pm) {
    var event = pm.event;
    var row = getData(object, event.id);
    if (
      notClickableCheck &&
      checkWhere(row, notClickableCheck, {object: object})
    )
      return;

    if (view.sub) view.sub.close({switching: true});
    var pm2 = {
      func: 'detail',
      object: object,
      objectSub: objectSub,
      id: event.id,
    };
    pm2.callback = function () {
      $(elements[object][event.id]['short_els']).each(function () {
        if ($(this).hasClass('fc-event')) {
          var activeEvent = $(this).addClass('active');
          var scroller = element.find(
            '.fc-scrollgrid-section-body td[role=presentation]:last .fc-scroller'
          )[0];
          if (scroller)
            scroller.scrollLeft =
              activeEvent.parent().position().left - view.width() / 2;
        }
      });
    };
    pm2.afterClose = function () {
      $(elements[object][event.id]['short_els']).each(function () {
        if ($(this).hasClass('fc-event')) $(this).removeClass('active');
      });
    };
    ajax(pm2);
  };

  // event mouseover
  pm.eventMouseEnter = function (pm) {
    if ($(pm.el).hasClass('with-tooltip')) return;

    var row = data[object][pm.event.id];
    var tip = row.short;
    if (row.descr) tip += '<br>' + row.descr;
    $(pm.el).addTooltip(tip).addClass('with-tooltip');
  };

  // event init
  pm.eventDidMount = function (pm) {
    var event = pm.event;
    var row = getData(object, event.id);
    var el = $(pm.el).attr('id', param.ident + '_' + event.id);
    rendered.push(event.id);

    // add badge + time + description
    var eventTitle = el.find('.fc-event-title');
    if (
      row.start &&
      !row.allDay &&
      !inArray(pm.view.type, ['timeGridDay', 'timeGridWeek'])
    )
      eventTitle.prepend(
        '<span class="me-1">' +
          row.start.substr(11, 5) +
          ' - ' +
          row.end.substr(11, 5) +
          '</span>'
      );
    if (row.warning)
      eventTitle.prepend(
        '<span class="badge rounded-pill me-1 bg-' + row.warning + '"></span>'
      );
    if (event.extendedProps.description)
      eventTitle.append(
        '<div class="fc-event-descr">' +
          event.extendedProps.description +
          '</div>'
      );

    // add context menu
    if (!event.read) {
      var context = {
        copy: info.context.doubleRow,
        disable: info.context.disableRow,
      };
      if (param.resource && row[param.resource])
        context.removeConnection = info.context.removeConnection;
      $.each(pm.resources, function (i, resource) {
        if (resource.id)
          context[resource.id] = info.tooltip.sendMailCustomer.replace(
            '__name__',
            resource.title
          );
      });
      contextMenu(el, context, function (type, target) {
        if (type == 'removeConnection') {
          var resourceId = parseInt(
            target.parents('td').attr('data-resource-id')
          );
          row[param.resource] = arrayRemove(row[param.resource], resourceId);
          if (!row[param.resource].length) row[param.resource] = '';
          var pm2 = {
            object: object,
            objectSub: objectSub,
            id: event.id,
            post: true,
            send: {},
          };
          pm2.send[param.resource] = row[param.resource];
          ajax(pm2);
        } else if (type == 'disable')
          ajax({
            object: object,
            objectSub: objectSub,
            id: event.id,
            post: true,
            send: {disabled: 1},
          });
        else if (type == 'copy')
          copy(row.id, {
            object: object,
            objectSub: objectSub,
          });
        else if (param.resource) {
          var send = {};
          send[param.resource] = type;
          ajax({object: object, id: event.id, post: true, send: send});
        }
      });
    }

    el.calendar = true;
    var regKeys = ['start', 'end', 'color']; // 'title', 'type',
    if (objectSub && parameter[object]['short' + ucfirst(objectSub)])
      regKeys.push('short' + ucfirst(objectSub));
    else regKeys.push('short');
    if (param.resource) regKeys.push(param.resource);
    $.each(regKeys, function (i, key) {
      register(el, object, event.id, key);
    });
    el.val = function () {
      if (settings['noUpdate' + ucfirst(objectSub)]) return;
      if (row.updated) {
        delete event.updated;
        return;
      }
      table.rem(row);
      if (!row.disabled) table.add(row);
    };
    if (!inArray(event.id, registered)) registered.push(event.id);
  };

  // view loaded
  pm.datesSet = function (pm) {
    table.calendarView = pm.view.type;

    // resources
    if (param.resource) {
      if (data.lists && data.lists[resourceIdent]) {
        if (resourceFilter)
          $.each(resourceFilter, function (i, id) {
            list[id] = data.lists[resourceIdent][id];
          });
        else list = data.lists[resourceIdent];
        table.addResources(list);
      } else table.getResourceData();
    }

    // load data
    else table.loadData({}, this, pm.startStr, pm.endStr);

    // day right click
    contextMenu(
      element.find('[data-date],[data-time]'),
      {add: 'Termin anlegen'},
      function (type, target, label, evt) {
        if (type == 'add') {
          var targets;
          var date = $(target).attr('data-date');
          if (!date) {
            targets = document.elementsFromPoint(
              evt.originalEvent.clientX,
              evt.originalEvent.clientY
            );
            date = $(targets).filter('[data-date]').attr('data-date');
          }
          if (str_contains(date, 'T')) date = date.replace('T', ' ');

          var settings = fields[object][param.startKey];
          var pm = {object: object, objectSub: objectSub, adopt: {}};
          pm.adopt[param.startKey] = date;
          if (settings.type == 'datetime' && !str_contains(date, ':')) {
            var time = $(target).attr('data-time');
            if (!time) time = calculate(settings.defaultTime);
            pm.adopt[param.startKey] += ' ' + time;
          }

          if (param.resource) {
            if (!targets)
              targets = document.elementsFromPoint(
                evt.originalEvent.clientX,
                evt.originalEvent.clientY
              );
            var resourceId = $(targets)
              .filter('[data-resource-id]')
              .attr('data-resource-id');
            if (resourceId) pm.adopt[param.resource] = resourceId;
          }

          detail(pm);
          return false;
        }
      }
    );

    // slot labels
    if (param.resource) {
      var format = [];
      if (
        table.calendarView == 'resourceTimelineMonth' ||
        table.calendarView == 'resourceTimelineWeek2'
      )
        format.push({week: 'short'}, {weekday: 'short', day: 'numeric'});
      else format = null;
      cal.setOption('slotLabelFormat', format);
    }

    // jump to today
    // if (param.resource)
    // 	setTimeout(function () {
    // 		var today = element.find('.fc-day-today');
    // 		if (!today[0]) return;
    // 		var left = today.offset().left;
    // 		element.find('thead .fc-scroller:eq(1)')[0].scrollLeft = left;
    // 		element.find('tbody .fc-scroller:eq(1)')[0].scrollLeft = left;
    // 	}, 500);
  };

  // event dropped
  pm.eventDrop = pm.eventResize = function (pm) {
    var event = pm.event;
    var send = {};
    send.allDay = event.allDay ? 1 : 0;
    send[param.startKey] = event.startStr;
    if (event.end && send.allDay)
      send[param.endKey] = moment(event.end)
        .subtract(1, 'day')
        .format('YYYY-MM-DD');
    // not using iso standard
    else if (event.end) send[param.endKey] = event.endStr;

    if (param.resource && pm.newResource) {
      var oldId = parseInt(pm.oldResource.id);
      var newId = parseInt(pm.newResource.id);
      if (fields[object][param.resource].array && !newId)
        send[param.resource] = null;
      else if (fields[object][param.resource].array) {
        var values = Object.assign([], data[object][event.id][param.resource]);
        if (!values) values = [];
        send[param.resource] = values;
        if (!pm.jsEvent.shiftKey)
          send[param.resource] = arrayRemove(send[param.resource], oldId);
        send[param.resource].push(newId);
      } else send[param.resource] = newId;
    }
    ajax({object: object, id: event.id, post: true, send: send});
  };

  // build
  var cal = new FullCalendar.Calendar(element[0], pm);
  cal.render();

  table.add = function (row) {
    if (settings['noUpdate' + ucfirst(objectSub)]) return;
    cal.addEvent(convertEvent(row, param));
    table.ids.push(row.id);
  };
  table.rem = function (row) {
    if (settings['noUpdate' + ucfirst(objectSub)]) return;
    var event = cal.getEventById(row.id);
    if (event) event.remove();
    table.ids = arrayRemove(table.ids, row.id);
  };
  table.clear = function () {
    $.each(cal.getEvents(), function (i, event) {
      event.remove();
    });
    table.ids = [];
  };
  view.setContentWidth = function () {
    cal.updateSize();
  };
  view.setContentHeight = function (height) {
    heightCalendar = height - 200;
    cal.setOption('contentHeight', heightCalendar);
  };

  // add calendar switcher
  var pmSwitcher = {
    field: 'select',
    value: pm.initialView,
    placeholder: 'Kalenderansicht',
    noSort: true,
    save: function (val) {
      if (!val) return;

      cal.changeView(val);
      if (!view.viewSelected)
        localStorage.setItem(param.ident + '_ViewInitial', val);
    },
    values: viewValues,
    labels: viewLabels,
    view: view,
  };
  viewSwitcher = buildFormLine(
    view.body.find('.fc-header-toolbar'),
    pmSwitcher
  );

  // calendar scroller
  // element.on('wheel', function (evt) {
  // 	// scroll horizontal
  // 	if (param.resource) {
  // 		element.find('thead .fc-scroller:eq(1)')[0].scrollLeft += evt.originalEvent.deltaY;
  // 		element.find('tbody .fc-scroller:eq(1)')[0].scrollLeft += evt.originalEvent.deltaY;
  // 	}

  // 	// jump months
  // 	else {
  // 		if ($(evt.target).parents('.fc-scrollgrid-section-body')[0]) {
  // 			var scroller = element.find('tbody .fc-scroller');
  // 			var scrollbar = scroller.children().height() > scroller.height();
  // 			if (scrollbar) return;
  // 		}

  // 		var direction = evt.originalEvent.deltaY > 0 ? 'next' : 'previous';
  // 		if (direction == 'next') cal.next();
  // 		else cal.prev();
  // 	}
  // });

  if (param.modal)
    setTimeout(function () {
      cal.updateSize();
    }, 500);

  // add mini navigator
  setTimeout(function () {
    if (view.navigator) view.navigator.remove();
    view.navigator = $(
      '<div class="calendar-navigator card h-50 p-3"></div>'
    ).appendTo(view.right);
    var pm2 = {
      initialView: 'multiMonthYear',
      locale: 'de',
      weekNumbers: true,
      schedulerLicenseKey: schedulerLicenseKey,
      dateClick: function (val) {
        cal.gotoDate(val.dateStr);
      },
    };
    var navigatorCal = new FullCalendar.Calendar(view.navigator[0], pm2);
    navigatorCal.render();
  }, 500);

  return element;
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function convertEvent(row, param, event) {
  if (!event) event = {};
  event.id = row.id;
  if (parameter[param.object].patternTitle)
    event.title = convertPattern({
      text: parameter[param.object].patternTitle,
      row: row,
      object: param.object,
      noAvatar: true,
    });
  else event.title = row.short;
  if (parameter[param.object].patternDescr)
    event.description = convertPattern({
      text: parameter[param.object].patternDescr,
      row: row,
      object: param.object,
      noAvatar: true,
    });
  event.start = row[param.startKey];
  if (param.endKey) event.end = row[param.endKey];
  if (!event.end) event.end = event.start;
  if (row.allDay || !param.endKey) event.allDay = true; //if (row.week) event.allDay = false;
  if (event.allDay && event.start != event.end)
    event.end = moment(event.end).add(1, 'day').format('YYYY-MM-DD'); // not using iso standard
  if (row.color) event.classNames = ['event-' + row.color];
  event.rerunId = row.rerunId;
  if (isReadMode(row, param)) event.editable = false;
  else event.editable = true;
  if (param.resource) {
    event.resourceIds = row[param.resource];
    if (!event.resourceIds || !event.resourceIds.length)
      event.resourceIds = [0];
  }
  return event;
}
